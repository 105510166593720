import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { COLORS } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { history } from '../../history';
import RMModalAddApp from './RMModalAddApp';
import RMModalAddPoolLicense from '../../components/license/RMModalAddPoolLicense';
import { RMLICENSETYPE, RMLICENSETYPE_MOCKUP } from '../../constants/RMConstants';
import { useTranslation } from 'react-i18next';

const RMLicenseAppInfoPanel = ({ managedApp, callbackok, pools }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [loading, setLoading] = useState(false);
	const [modalAdd, setModalAdd] = useState({ show : false, managedapp : true });
	const [modalAddPool, setModalAddPool] = useState({ show : false });
	const [available, setAvailable] = useState(false);

	const RMLCTYPES = RMLICENSETYPE;//managedApp?._id && managedApp?._id.includes("DigitalSmileDesignInAppExportMockUp") ? RMLICENSETYPE_MOCKUP : RMLICENSETYPE;
	const partner = process.env.REACT_APP_COMPANY || 'ray';
    const rayfusion_demo = {
        activate_valid_period : 90 * 24 * 3600,         //license valid(activation) - 3개월
        activated_expired_period : 90 * 24 * 3600,      //activated 이후, activated 시점부터 3개월동안 사용가능
        activated_grace_period : 5 * 24 * 3600,         //activated 이후, 5일동안 Relu License Server 접속이 없으면, 사용중지
    }
	useEffect(() => {
		if(managedApp) {
			setAvailable(RMLCTYPES.map(x => {
				return ( managedApp.hasOwnProperty(`t_${x.value}`) && 
					(managedApp.hasOwnProperty(`u_${x.value}`) ? parseInt(managedApp?.[`u_${x.value}`]) : 0) === (managedApp.hasOwnProperty(`t_${x.value}`) ? parseInt(managedApp?.[`t_${x.value}`]) : 0) ? false : true
				)
			}));
		}
	}, [])

	const showLcAdd = () => {
		if(partner != "ray") {
			const b2bPlCount = pools.filter(f => f.sk.includes("pl:") && f.b2b == partner && f.used == "")?.length || 0;
			if (b2bPlCount == 0) {
				alert("사용 가능한 라이선스 가 없습니다. 관리자에 문의 하세요.");
			} else {
				setModalAdd({ show : true, managedapp : true, title : managedApp.title });
			}
		} else {
			setModalAdd({ show : true, managedapp : true, title : managedApp.title });
		}
	};

	const modalAddComplete = async ()  => {
		callbackok && callbackok();
	}

    const cntAll = (tp) => {
		const obj = {
			pltotal : pools?.filter(x => x.sk.indexOf("pl:" + tp + ":") > -1  && x?.b2b == partner && (tp === "normal" ? x.period == 365 : true)),
			plusecanceled : pools?.filter(x => x.sk.indexOf("lcuse:") > -1 && x.sk.indexOf(":" + tp + ":") > -1 && x.expired === 1 && x?.b2b == partner
			&& (managedApp._id == "App-RAYFusion" ? (tp === "normal" ? pools?.find(f => f.sk.indexOf("pl:" + tp + ":" + x.sk.split(":")[5]) > -1 )?.period === 365 : true) : true)) ,
			plusedall : pools?.filter(x => x.sk.indexOf("lcuse:") > -1 && x.sk.indexOf(":" + tp + ":") > -1 && x.expired !== 1 && x.expired > moment().valueOf() && x?.b2b == partner
			&& (managedApp._id == "App-RAYFusion" ? (tp === "normal" ? pools?.find(f => f.sk.indexOf("pl:" + tp + ":" + x.sk.split(":")[5]) > -1 )?.period === 365 : true) : true)) ,
			plusedallex : pools?.filter(x => x.sk.indexOf("lcuse:") > -1 && x.sk.indexOf(":" + tp + ":") > -1 && x.expired <= moment().valueOf() && x.expired !== 1 && x?.b2b == partner
			&& (managedApp._id == "App-RAYFusion" ? (tp === "normal" ? pools?.find(f => f.sk.indexOf("pl:" + tp + ":" + x.sk.split(":")[5]) > -1 )?.period === 365 : true) : true)) ,
		}
		return {...obj, remain : obj.pltotal.length - obj.plusedall.length };
    }

    const getDaysByType = (tp) => {
        return pools?.find(x => x.sk === "type:" + tp)?.period || "";
    }

	const getDueDate = (tp) => {
		const duedate = pools?.find(x => x.sk === "type:" + tp)?.dueDate;
		return duedate ? moment.unix(duedate / 1000).format("yyyy-MM-DD") : "";
	}

	const getReSub = (tp) => {
		const resub = pools?.find(x => x.sk === "type:" + tp)?.noresub;
		return resub ? "No ReSubscription" : "";
	}

	const getAutoSub = (tp) => {
		const autosub = pools?.find(x => x.sk === "type:" + tp)?.autosub;
		return autosub ? "Auto Subscription" : "";
	}

    const num = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

	return 	<>
	{!loading && <>
		{managedApp && <div>
				<div className='w-100'>
                    <div className="d-flex justify-content-between">
                        <h2>{managedApp?.title}</h2>
                        <div className='text-muted text-end card-toolbar mb-4'>
                            <div>
                                <MLButton options={{
                                    code: "lic-addpool",
                                    label : t("Add License"),
                                    color : COLORS.PRIMARY,
                                    action : () => setModalAddPool({show : true})
                                }} css='me-2' />
                                {available && available.find(f => f == true) && <MLButton options={{
                                    code: "lic-assignlc",
                                    label : t("License 부여"),
                                    color : COLORS.PRIMARY,
                                    action : () => showLcAdd(true)
                                }} css='me-2' />}
                                <MLButton options={{
                                    label : t("목록"),
                                    color : COLORS.DARK,
                                    action : () => history.push('/LicensePage')
                                }} />
                            </div>
                        </div>
                    </div>
					<div className="d-flex flex-wrap flex-stack mt-6">
						<div className="row">
							{partner != "ray" && RMLCTYPES.map(x => {
								const stat = cntAll(x.value);
								return ( managedApp.hasOwnProperty(`t_${x.value}`) && <> 
									<div className="col mb-3" title={x._id}>
										<div className="d-flex justify-content-between mb-2">
											<div>
												{x.label}
											</div>
											<div>
												{/* RAYFusion Demo, Development 라이선스의 경우 남은 수량을 보여주지 않는다. (신승훈상무님 요청) */}
												{(!pools.find(x => x.sk === "info")?.isray && !(managedApp._id == "App-RAYFusion" && x.value == "demo" || x.value == "development" )) && num(stat.pltotal.length)}
											</div>
										</div>
										<table className="table table-striped bg-light">
											<thead>
												<tr>
													<th className="px-4 text-end">{t("Active")}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="px-4 text-end">{num(stat.plusedall.length)}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</>)
							})}
							{partner == "ray" && RMLCTYPES.map(x => {
								const stat = cntAll(x.value);
								return ( (managedApp.hasOwnProperty(`t_${x.value}`) || managedApp.hasOwnProperty(`t_${x.value}:ray`)) && <> 
									<div className="col mb-3" title={x._id}>
										<div className="d-flex justify-content-between mb-2">
											<div>
												{(x.value == "normal" && getDaysByType(x.value) == 365) ? "Annual" : (x.value == "normal" && getDaysByType(x.value) == 30 ? "Monthly" :  x.label)}
												{getDueDate(x.value) == "" ? <span className="ms-1 text-gray-600">{(x.value == "development" || x.value == "demo") ? "" : "(" + (getDaysByType(x.value) + t("Days")) + ")"}</span> : 
													<span className="ms-1 text-gray-600">({getDueDate(x.value) + " Date"})</span>
												}
												{getReSub(x.value) == "" ? "" : <span className="badge bg-secondary ms-3">{getReSub(x.value)}</span>}
												{getAutoSub(x.value) == "" ? "" : <span className="badge bg-success ms-3">{getAutoSub(x.value)}</span>}
											</div>
											<div>
												{(!pools.find(x => x.sk === "info")?.isray && !(managedApp._id == "App-RAYFusion" && x.value == "demo" || x.value == "development" )) && num(stat.pltotal.length)}
											</div>
										</div>
										<table className="table table-striped bg-light">
											<thead>
												<tr>
													<th className="px-4 text-end">{t("Active")}</th>
													<th className="px-4 text-end">{t("Canceled")}</th>
													<th className="px-4 text-end">{t("Expired")}</th>
													{(!pools.find(x => x.sk === "info")?.isray && !(managedApp._id == "App-RAYFusion" && x.value == "demo" || x.value == "development" )) && <th className="text-end text-white">{t("Remain")}</th>}
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="px-4 text-end">{num(stat.plusedall.length)}</td>
													<td className="px-4 text-end">{num(stat.plusecanceled.length)}</td>
													<td className="px-4 text-end">{num(stat.plusedallex.length)}</td>
													{(!pools.find(x => x.sk === "info")?.isray && !(managedApp._id == "App-RAYFusion" && x.value == "demo" || x.value == "development" )) && <td className="text-end">{num(stat.remain - stat.plusedallex.length - stat.plusecanceled.length)}</td>}
												</tr>
											</tbody>
										</table>
									</div>
								</>)
							})}
						</div>
					</div>
				</div>
		</div>}
	</>}
	<RMModalAddApp callbackClose={() => {setModalAdd({show : false}); modalAddComplete();}} modalData={modalAdd} />
	<RMModalAddPoolLicense callbackClose={() => {setModalAddPool({ show: false }); }} modalData={modalAddPool} />
	</>
};

const mapState = (state) => {
	const user =  state.AuthReducer.user;
	const region =  state.AuthReducer.region;
    const managedApp = state.AppCategoryReducer.managedApp;
    const pools = state.LicenseReducer.pools;

	return { region, user, managedApp, pools };
};

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, null)(RMLicenseAppInfoPanel);
