import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/common/Loader';
import { MLForm } from '../../components/common/MLForm';
import { BUTTONS, COLORS, INPUTS } from '../../constants/FromInputs';
import { RTAudit } from '../../libs/audit';
import { AWSAdminLoginPasswordUpdate } from '../../libs/aws';
import { AlertConstant } from '../../redux/reducers';
import { AuthConstant } from '../../redux/reducers/AuthReducer';

const RMPasswordUpdate = ({ adminUsers, user, loginuser, uData, myInfo = false }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const { _id } = useParams();
	const [data, setData] = useState({ password: "" });
	const [loading, setLoading] = useState(false);
	const [passConfirmErr, setPassConfirmErr] = useState('');

	const updatePassword = async () => {
		setLoading(true);
		try {
			if (adminUsers && !myInfo) {
				const adminUserInfo = adminUsers.filter(y => y.sub === _id)[0];
				const ret = await AWSAdminLoginPasswordUpdate(adminUserInfo.email, data.password, adminUserInfo.sub);
				if (ret.fail) {
					alert("이전에 사용했던 비밀번호는 다시 사용할 수 없습니다");
					return;
				}
				RTAudit({ lvl: 7, msg: '[' + uData.name + '] password updated by manager' });

				if (adminUserInfo.email === user?._id) {
					localStorage.clear();
					dispatch({ type: AuthConstant.LOGOUT });
				}
			}

			if (myInfo && uData) {
				const ret = await AWSAdminLoginPasswordUpdate(uData?.email, data.password, uData?.sub);
				if (ret.fail) {
					alert("이전에 사용했던 비밀번호는 다시 사용할 수 없습니다.");
					return;
				}
				RTAudit({ lvl: 7, msg: '[' + uData.name + '] password updated' });
				dispatch({ type: AlertConstant.NOTICE, msg: t('Password 변경 완료'), show: true });
			}


		} catch (error) {
			console.error("Error updating password:", error);
		} finally {
			setLoading(false);
		}
	};

	const controls = [
		{
			type: INPUTS.PASSWORD,
			options: {
				label: t('New Password'),
				name: 'password',
				required: true,
				useToggle: true,
				isValidPassword: true
			}
		},
		{
			type: INPUTS.PASSWORD,
			options: {
				name: 'repassword',
				label: t('Confirm Password'),
				err: passConfirmErr,
				required: true,
				useToggle: true,
				isMatch: true,
				isValidPassword: true,
				originPassword: data?.password,
			}
		},
		{
			type : BUTTONS.BUTTONS, 
			options : { },
			buttons : [{
				type : BUTTONS.NORMALBUTTON, 
				options: { 
					//code: "ac-updatepw",
					label : t('암호변경'), 
					color : COLORS.PRIMARY,
					needValid : true,
                    action : () => updatePassword(),
				}
			}]
		}
	];

	return (
		<div className=''>
			{loading && <Loader />}
			{!loading && <div className='p-4'>
				<MLForm controls={controls.filter(x => !x.perm)} data={data} nocard={true} onChanged={(e) => { console.log(e); setData(e); }} />
			</div>}
		</div>
	)
}

const mapState = (state) => {
	const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
	return { adminUsers, user };
};

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(RMPasswordUpdate);
