import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { COLORS, VALUES } from '../../constants/FromInputs';
import { MLButton } from './MLButton';
import DatePicker from "react-datepicker";
import { ToggleButton, ButtonGroup } from "react-bootstrap";
import { Editor } from '@tinymce/tinymce-react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { MLToolTip } from './MLToolTip';
import { emailRegex, passwordRegex } from '../../libs/regexs';

const getCheckNullView = (options, val) => {
	if (options.type === VALUES.WORKFLOW) {
		return <p className='fs-16 form-value'>
			<Link to={"/Admin/AdminWFInfo/" + options.value}>
				{val}
			</Link>
		</p>
	}
	if (options.type === "DATE") {
		console.log(val);
		return <div className='fs-16 form-value'>
			{moment.utc(val).local().format('YYYY-MM-DD HH:mm')} ({moment.utc(val).local().fromNow()})
		</div>;
	}
	return val ?
		<div className='fs-16 form-value'>{nl2br(val)}</div> :
		(val === 0 ? <div className='fs-16 form-value'>0</div> :
			<div className='fs-16 form-value'><i>N/A</i></div>);
}

const nl2br = (text) => {
	var html = [];
	if (text && typeof text === 'string' || text instanceof String) {
		text.split('\n').map((line, idx) => {
			html.push(<React.Fragment key={idx}>{line}<br /></React.Fragment>);
		})
	} else if (text) {
		return text;
	} else {
		return "N/A";
	}
	return html;
}

export const MLGroup = ({ options = {}, handleChanged, readonly = false }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options.value || "");

	useEffect(() => {
		//setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		//setVal(e.target.value);
		//handleChanged && handleChanged(e);
	}

	return <div name={options.name}
		placeholder={options.placeholder}
		value={val || ''}
		size={options.size || ''} className="py-2">
		<label>{options.grouptitle}</label>
	</div>
}

export const MLHidden = ({ options = {}, handleChanged, readonly = false }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options.value || "");

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	return <input type="hidden"
		name={options.name}
		onFocus={() => setFocus(true)}
		onBlur={() => setFocus(false)}
		placeholder={options.placeholder}
		onChange={onChanged}
		value={val || ''}
		size={options.size || ''}
		className="form-control" required="" />
}

export const MLNumber = ({ options = {}, handleChanged, readonly = false, css = " mb-3" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options.value || 0);

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	return <div className={"form-group" +
		((css ? css : "mb-3 fv-plugins-icon-container  ")) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'required form-label fw-bolder' : 'form-label fw-bolder'}>{options.label || (options.name)}<span /></label>}
			<input type="number"
				name={options.name}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				placeholder={options.placeholder}
				onChange={onChanged}
				value={val || 0}
				size={options.size || ''}
				className={"form-control" + (options.size ? " form-control-" + options.size : " ")} required="" />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			{!options.nolabel && <label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>}
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLDate = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(new Date());

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		setVal(e);
		handleChanged && handleChanged({ target: { name: options.name, value: e } });
	}

	return <div className={"form-group" +
		(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			<label className={options.required ? 'required' : ''}>{options.label || (options.name)}<span /></label>
			<DatePicker
				dateFormat="yyyy-MM-dd"
				name={options.name}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				size={options.size || ''}
				selected={val}
				onChange={onChanged}
				className={"form-control"} required="" />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='label'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLText = ({ options = { value: "" }, enterSubmit, handleChanged, readonly = false, css = "", style = {} }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options?.value || "");

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	const onKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (options.enterSubmit && enterSubmit) {
				enterSubmit();
			}
		}
	}

	return <div className={"" +
		((css ? css : "mb-3 fv-plugins-icon-container  ")) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required fw-bolder' : 'form-label fw-bolder'}>{options.label}<span /></label>}
			<input type="text"
				name={options.name}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				placeholder={options.placeholder}
				// autoComplete={typeof options.autoComplete === 'boolean' ? (options.autoComplete ? 'on' : 'off') : undefined}
				autoComplete={options.autoComplete ? 'on' : 'off'}
				onChange={onChanged}
				onKeyPress={onKeyPress}
				style={style}
				value={val || ''}
				size={options.size || ''}
				className={"form-control"} required="" />
			{options.err && <label className="error" htmlFor={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			{!options.nolabel && <label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>}
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLHTMLEditor = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options.value || "");
	const editorRef = useRef(null);

	useEffect(() => {
		if (options.isEdit && !val) {
			setVal(options.value);
		}
	}, [options, val]);


	const onChanged = (e) => {
		handleChanged && handleChanged({ target: { name: options.name, value: e } });
	}

	return <div className={"" +
		(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span /></label>}
			<Editor
				apiKey='d5m9c6cyc34z2jh0dxtoc5l1fmc9jbgot1qgr2xzj2am614l'
				textareaName={options.name}
				onInit={(evt, editor) => editorRef.current = editor}
				initialValue={val}
				onEditorChange={onChanged}
				init={{
					height: options.height || 500,
					menubar: false,
					browser_spellcheck: true,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					toolbar: 'undo redo | formatselect | fontsizeselect | ' +
						'bold italic underline forecolor backcolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist outdent indent | ' +
						'removeformat | ' + options?.addMenuButtons?.map(x => x.name)?.join(' | ') +
						' | ' + options?.cusTomToolbars?.map(x => x.name)?.join(' | '),
					fontsize_formats: '10px 14px 18px 24px 36px',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
					setup: function (editor) {
						options?.cusTomToolbars?.forEach(x => {
							editor.ui.registry.addButton(x.name, {
								text: x.text,
								onAction: x.onAction(editor)
							});
						})
						options?.addMenuButtons?.forEach(x => {
							editor.ui.registry.addMenuButton(x.name, {
								text: x.text,
								fetch: (callback) => x.fetch(callback, editor)
							});
						})
					}
				}}
			/>
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLTextArea = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options.value || "");
	const [areaEl, setAreaEl] = useState(null);

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	const autoGrow = (el) => {
		if (!options.maxheight) {
			return;
		}
		const target = el?.target;
		if (!target) {
			return;
		}
		const defaultHeight = options.rows ? options.rows * 30 : 90;
		if (!el.target.value) {
			target.style.height = defaultHeight + 'px';
			return;
		}
		setAreaEl(target);
		const scrollHeight = target.scrollHeight;
		const targetHeight = target.style.height ? Number(target.style.height.replace(/[^\d]/g, '')) : 0;
		if (defaultHeight < scrollHeight && scrollHeight < options.maxheight && targetHeight < scrollHeight) {
			target.style.height = scrollHeight + 'px';
		}
	}

	useEffect(() => {
		if (!options?.value && areaEl) {
			areaEl.style.height = (options.rows ? options.rows * 30 : 90) + 'px';
		}
	}, [areaEl, options?.value, options?.rows])

	return <div className={"" +
		(css && " " + css) +
		(focus ? " focused" : " focus-out") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span /></label>}
			<textarea
				name={options.name}
				ref={options?.attachRef}
				onFocus={() => setFocus(true)}
				onBlur={() => { setFocus(false); options.onBlur && options.onBlur(val); }}
				placeholder={options.placeholder}
				onChange={onChanged}
				style={{ transition: 'height 0.2s', height: (options.rows ? (options.focusRows ? ((focus || val) ? options.focusRows * 44 : options.rows * 44) : options.rows * 44) : 90), maxHeight: options.maxheight }}
				value={val || ''}
				onKeyPress={options?.onKeyPress}
				onPaste={options?.onPaste}
				size={options.size || ''}
				onInput={autoGrow}
				className={"form-control form-control-textarea form-control-sm " + (options?.focusRows ? ' has-focus-row ' : '')}
				required="">{val}</textarea>
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLEmail = ({ options = {}, enterSubmit, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(options.value || "");
	const [emailValid, setEmailValid] = useState(true);

	useEffect(() => {
		setVal(options.value);
	}, [options])

	useEffect(() => {
		if (!val) {
			setEmailValid(true)
		}
		if (options?.isValidEmail && !focus && val) {
			let valid = emailRegex.test(val);
			setEmailValid(valid)
			if (valid && options?.callbackEmailValid) {
				const check = options?.callbackEmailValid;
				check(val);
			}
		}
	}, [focus, val, options?.isValidEmail, options?.callbackEmailValid]);

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	const onKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (options.enterSubmit && enterSubmit) {
				enterSubmit();
			}
		}
	}

	return <div className={"mb-3 fv-plugins-icon-container " +
		(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={(options.required && !options.hideRequired) ? 'form-label required' : 'form-label'}>{options.label}<span /></label>}
			<input type="email"
				name={options.name}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				placeholder={options.placeholder}
				autoComplete={options.autoComplete ? 'on' : 'off'}
				onChange={onChanged}
				value={val || ''}
				onKeyPress={onKeyPress}
				size={options.size || ''}
				className="form-control" required="" />
			{options.err && <label className="error text-danger label-input-bottom" htmlFor={options.name}>{options.errmsg}</label>}
			{!emailValid && options?.isValidEmail && <label className="error text-danger label-input-bottom" htmlFor={options.name}>{options?.invalidEmailLabel ?? 'Invalid Email Format'}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLPassword = ({ options = {}, enterSubmit, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
	const [inputType, setInputType] = useState('password');
	const [passwordValid, setPasswordValid] = useState(true);
	const [passwordMatch, setPasswordMatch] = useState(true);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		setVal(options.value);
	}, [options])

	useEffect(() => {
		if (!val) {
			setPasswordValid(true);
		}
		if (options?.isValidPassword && focus && val) {
			let valid = passwordRegex.test(val);
			setPasswordValid(valid);
		}
		if (options?.isMatch && focus && val) {
			if (options.value === options?.originPassword)
				setPasswordMatch(true);
			else {
				setPasswordMatch(false);
			}

		}
	}, [focus, val, options?.isValidPassword, options?.isMatch, handleChanged])

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	const onKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (options.enterSubmit && enterSubmit) {
				enterSubmit();
			}
		}
	}

	return <div className={"mb-3 fv-plugins-icon-container " +
		(css && " " + css) +
		(options.css && " " + options.css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.forgot && <div className='d-flex justify-content-between'>
				{options.label && <label className={(options.required && !options.hideRequired) ? 'form-label required fw-bolder' : 'form-label fw-bolder'}>{options.label}<span /></label>}
				<a onClick={options.forgotlink} className="cursor-pointer"><small>{options.forgotmsg}</small></a>
			</div>}
			{!options.forgot && <>
				{options.label && <label className={(options.required && !options.hideRequired) ? 'form-label required fw-bolder' : 'form-label fw-bolder'}>{options.label}<span /></label>}
			</>}

			<MLToolTip children={
				<div className='position-relative'>
					<input type={inputType}
						name={options.name}
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
						placeholder={options.placeholder}
						onChange={onChanged}
						onKeyPress={onKeyPress}
						value={val || ''}
						size={options.size || ''}
						className={"form-control " + (options.err ? "mb-0" : "")} required="" />
					{options?.useToggle && <div className='position-absolute d-flex h-100 align-items-center pe-3' style={{ right: 0, top: 0 }}>
						{inputType === 'password' && <AiOutlineEye size={24} className="raycur text-muted" onClick={() => setInputType('text')} />}
						{inputType === 'text' && <AiOutlineEyeInvisible size={24} className="raycur text-muted" onClick={() => setInputType('password')} />}
					</div>}
				</div>
			}
				tooltip={<div className='text-start w-300px'>
					{t('The password has the following rules:')}
					<ul className='mt-2 mb-0'>
						<li>{t('At least 8 characters')}</li>
						<li>{t('At least one uppercase letter')}</li>
						<li>{t('One or more lowercase letters')}</li>
						<li>{t('One or more numbers')}</li>
						<li>{t('One or more special characters')}</li>
					</ul>
				</div>}
				hide={options?.tooltiphide} />
			{options.err && <label className="text-danger mb-2" htmlFor={options.name}>{options.err}</label>}
			{!passwordValid && options?.isValidPassword && <label className="text-danger mb-2" htmlFor={options.name}>{options?.isValidPassword && 'The password has the following rules'}</label>}
			{!passwordMatch && options?.isMatch && <label className="text-danger mb-2" htmlFor={options.name}>{options?.isMatch && 'not match password'}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, 'HIDDEN')}
		</>}
	</div>
}

export const MLComboMulti = ({ options = {}, handleChanged, readonly = false, css = "mb-3" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState([]);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		if (options.list && options.value) {
			setVal(options.list.filter(x => options.value.includes(x.value)));
		}
	}, [options])

	const onChanged = (e) => {
		setVal(e);
		handleChanged && handleChanged({ target: { name: options.name, value: e.map(x => x.value) } });
	}

	const selectAllOption = {
		value: "<SELECT_ALL>",
		label: "전체"
	};

	const isSelectAllSelected = () => val?.length === options?.list?.length;

	const isOptionSelected = option => {
		if (val) {
			return val.some(({ value }) => value === option.value) || isSelectAllSelected();
		} else {
			return isSelectAllSelected();
		}
	}

	const getOptions = () => [selectAllOption, ...options.list];

	const getValue = () =>
		isSelectAllSelected() ? [selectAllOption] : val;

	const onChange = (newValue, actionMeta) => {
		console.log(newValue, actionMeta);
		const { action, option, removedValue } = actionMeta;
		if (action === "select-option" && option.value === selectAllOption.value) {
			onChanged(options.list);
		} else if ((action === "deselect-option" && option.value === selectAllOption.value) || (action === "remove-value" && removedValue.value === selectAllOption.value)) {
			onChanged([]);
		} else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
			onChanged(options.list.filter(({ value }) => value !== option.value));
		} else {
			onChanged(newValue || []);
		}
	};

	return <div className={(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required fw-bolder' : 'form-label fw-bolder'}>{options.label}<span /></label>}
			<Select
				isMulti
				size={"sm"}
				name={options.name}
				isOptionSelected={isOptionSelected}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={options.showalloption ? getValue() : val}
				options={options.showalloption ? getOptions() : options.list}
				onChange={options.showalloption ? onChange :  onChanged} />
			{options.err && <label className="error" htmlFor={options.name}>{options.errmsg}</label>} 
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>} 
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{val && getCheckNullView(options, val.map(m => m.label).join(", "))}
		</>}
	</div>
}

export const MLComboSingle = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");

	useEffect(() => {
		if (options.list)
			setVal(options.list.find(x => x.value === options.value) || '');
	}, [options])

	const onChanged = (e) => {
		if (options.isClearable && !e) {
			setVal('');
			handleChanged && handleChanged({ target: { name: options.name, value: '', valuelabel: options.list.find(x => x.value === e?.value)?.label || "" } });
			options.selected && options.selected(e);
		} else {
			setVal(e.value);
			handleChanged && handleChanged({ target: { name: options.name, value: e.value, valuelabel: options.list.find(x => x.value === e.value)?.label || "" } });
			options.selected && options.selected(e);
		}
	}

	return <div className={"" +
		((css ? css : "mb-3 fv-plugins-icon-container  ")) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required fw-bolder' : 'form-label fw-bolder'}>{options.label}<span /></label>}
			<Select
				size={"sm"}
				name={options.name}
				isDisabled={options.disabled}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={val || ''}
				isClearable={options.isClearable}
				className="text-dark"
				options={options.list}
				styles={{
					menu: provided => ({ ...provided, zIndex: 9999 })
				}}
				placeholder={options?.placeholder ?? 'Select...'}
				onChange={onChanged} />
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, val ? val.label : "")}
		</>}
	</div>
}

export const MLRadioButton = ({ options = {}, handleChanged, readonly = false, css = "mb-3" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState("");
	const [t] = useTranslation(['translation']);
	const formId = 'F' + new Date().valueOf();

	useEffect(() => {
		if (options.list) {
			if (options.value && options.value != val) {
				var n = options.list.filter(x => x.value == options.value);
				if (n.length > 0)
					setVal(n[0].value);
			} else if (!options.value && val == "") {
			}
		}
	}, [options])

	const onChanged = (e) => {
		setVal(e.target.value);
		handleChanged && handleChanged(e);
	}

	return <div className={"" +
		(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required fw-bolder' : 'form-label fw-bolder'}>{options.label}<span /></label>}
			{options.list && <div>
				<ButtonGroup className={options?.groupCss ? options?.groupCss : ''}>
					{options.list.map((r, idx) => {
						return <ToggleButton
							id={formId + "radio-" + options.name + "-" + idx}
							key={formId + "radio-" + options.name + "-" + idx}
							type="radio"
							variant={val === r.value ? 'primary' : 'outline-primary'}
							className={"border " + (options.size && "btn-" + options.size)}
							name={options.name}
							value={r.value}
							checked={val === r.value}
							onChange={(e) => onChanged(e)}>
							{r.label}
						</ToggleButton>
					})}
				</ButtonGroup>
			</div>}
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			{!options.nolabel && <label className='form-label fw-bolder'>{options.label || (options.name)}<span /></label>}
			{getCheckNullView(options, val || "")}
		</>}
	</div>
}

export const MLToggle = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState(false);
	const [t] = useTranslation(['translation']);

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onChanged = (e) => {
		setVal(e.target.checked);
		handleChanged && handleChanged({ target: { name: options.name, value: e.target.checked } });
	}

	return <div className={"" +
		((css ? css : "mb-3 fv-plugins-icon-container  ")) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{!options.inline && <div className='clearfix'>
				{options.label && <label className={(options.required ? 'form-label required fw-bolder ' : 'form-label fw-bolder ') + (options.labelCss ? options.labelCss : '')}>{options.label || (options.name)}<span /></label>}
				<div className={options.size ? "form-check form-check-" + options.size : "form-switch"}>
					{options.labelpos === "left" && <span style={{ float: "left", marginRight: 60 }}>{val ? options.truemsg : options.falsemsg}</span>}
					<input
						checked={val}
						onChange={onChanged}
						size={options.size}
						name={options.name}
						type="checkbox"
						style={{ float: "left" }}
						className="form-check-input me-2" />
					<div>
						{options.labelpos !== "left" && <span className={options.labelposCss ?? ''}>{val ? options.truemsg : options.falsemsg}</span>}
					</div>
				</div>
			</div>}
			{options.inline && <div className={options.size ? "form-check form-check-" + options.size : "form-switch"}>
				<input
					checked={val}
					onChange={onChanged}
					size={options.size}
					name={options.name}
					type="checkbox"
					className={"form-check-input me-1 " + (options?.boxCss ? options?.boxCss : ' h-30px w-50px')}
				/>
				{options.label && <label
					style={(options?.labelStyle ? options?.labelStyle : {})}
					className={(options.required ? 'required ' : '') + (options.labelCss ? options.labelCss : '')}>
					{options.label || (options.name)}
				</label>}
			</div>}
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			{!options.nolabel && <label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>}
			{getCheckNullView(options, val === true ? (options.truemsg || "Yes") : (options.falsemsg || "No"))}
		</>}
	</div>
}

export const MLAddItems = ({ options = {}, handleChanged, readonly = false, css = "" }) => {

	const [t] = useTranslation(['translation']);
	const [focus, setFocus] = useState(false);
	const [txt, setTxt] = useState("");
	const [val, setVal] = useState(options.value || []);

	useEffect(() => {
		setVal(options.value);
	}, [options])

	const onKeyPress = (e) => {
		if (e.key === "Enter") {
			setVal([...val, { value: e.target.value, label: e.target.value }]);
			handleChanged && handleChanged({
				target: {
					name: e.target.name,
					value: [...val, { value: e.target.value, label: e.target.value }]
				}
			});
			setTxt("");
		}
	}

	const onChanged = (e) => {
		setTxt(e.target.value);
	}

	const delitem = (idx) => {
		setVal(val.filter((x, index) => index !== idx));
		handleChanged && handleChanged({
			target: {
				name: options.getKey || "value",
				value: val.filter((x, index) => index !== idx)
			}
		});
	}

	return <div className={"" +
		(css && " " + css) +
		(focus ? " focused" : "") +
		(options.err ? " has-error" : "")}>
		{(!options.readonly && !readonly) && <>
			{options.label && <label className={options.required ? 'form-label required' : 'form-label'}>{options.label}<span /></label>}
			<input type="text"
				name={options.name}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				placeholder={options.placeholder}
				onChange={onChanged}
				onKeyPress={onKeyPress}
				value={txt || ''}
				size={options.size || ''}
				className={"form-control" + (options.size ? " form-control-" + options.size : " form-control-sm")} required="" />
			<ul className='list-group mt-1'>
				{val && val.map((x, idx) => <li className='list-group-item' key={"additems-" + idx}>
					{x.label}
					<MLButton options={{
						label: t('remove'),
						color: COLORS.DANGER,
						size: "sm",
						action: () => delitem(idx)
					}} css='float-end' />
				</li>)}
			</ul>
			{options.err && <label className="error" for={options.name}>{options.errmsg}</label>}
			{options.hint && <label className="text-light" htmlFor={options.name}>{options.hint}</label>}
		</>}
		{(options.readonly || readonly) && <>
			<label className='form-label fw-bolder'>{options.label || (options.name)}<span /><small className='ms-2 text-light'>{options.hint}</small></label>
			{getCheckNullView(options, options.value)}
		</>}
	</div>
}

export const MLSpanV = ({ options }) => {
	return <div style={{ height: options.size }}></div>
}

export const MLHR = ({ options, css = '' }) => {
	return <hr style={{ height: options.size }} className={css}></hr>
}