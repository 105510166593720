const initialState = { 
	credit : {},
	credits : [],
};

export const CreditConstant = {
	GET_CREDIT : "GET_CREDIT",
    GET_CREDITS : "GET_CREDITS",
};

export const CreditReducer = (state = initialState, action) => {
	switch (action.type) {
		case CreditConstant.GET_CREDIT:
			return {...state, credit : action.item };
		case CreditConstant.GET_CREDITS:
			return {...state, credits : action.items };
		default:
			return state;
	}
};
