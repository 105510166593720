import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import MLTable from '../../components/common/MLTable';
import { callDB } from '../../libs/db';
//import Clinic from '../components/clinic';

const ComApplicationFeedback = ({ user, title, application, applicationVersions, allgroups }) => {

	const dispatch = useDispatch();

	const [t] = useTranslation(['translation']);
	const [ items, setItems ] = useState([]);
	const [ edit, setEdit ] = useState(false);
	const [ data, setData ] = useState({});
	const [ process, setProcess ] = useState(false);

	useEffect(() => {
        getData();
	}, [application?.name]);

	const getData = async () => {        

        console.log('application', application);
		try {
			const ret = await callDB({
				type : "query",
				region : "ap-northeast-2",
				params : {
					TableName: "rayteams-feedback",
                    IndexName: "appname-index",
					KeyConditionExpression: "#appname = :appname",
					ExpressionAttributeNames: { "#appname": "appname" },
					ExpressionAttributeValues: { ":appname" : application.name }
				},
				filter : {}
			});
			setItems(ret ? ret.map(x => ({ userid : x.userinfo?._id, ...x})) : []);
		} catch (err) {
			console.log(err);
		}
	}
    console.log(items);

    const headers = [
		{ value : "userid", label : "User", css : "text-start", css : "w-200px", type : "USER", hide : false, useanony : true },
		{ value : "title", label : "Option", css : "text-start", css : "w-200px" },
        { value : "contents", label : "Content", css : "text-start" },
        { value : "version", label : "Version", css : "text-start" },
        { value : "created", label : "Created", css : "text-end", type : "DATESHORT", css : "w-150px" },
	]
    
	return <MLTable 
        headers={headers} 
        items={items} 
        order={"created"} 
        noheader={true} />
}

const mapState = (state) => {
	const user =  state.AuthReducer.user;
    const applicationVersions = state.ApplicationReducer.applicationVersions;
	return { user, applicationVersions };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(ComApplicationFeedback);
