import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ProgramConstant } from '../../redux/reducers';
import MLScope from '../../components/common/MLScope';
import { MLButton } from '../../components/common/MLButton';
import { MLComboSingle, MLNumber, MLRadioButton, MLText, MLToggle } from '../../components/common/MLInput';
import { COLORS } from '../../config/color';
import { Link, useParams } from 'react-router-dom';
import { fetchData, insertData,  updateData } from '../../libs/db';
import { history } from '../../history';
import { LANGUAGE } from '../../constants/RMConstants';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { ApplicationAction } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';

const NotificationsInfo = ({ applications, applicationVersions }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation(['translation']);
    const { _cid } = useParams();

    const [isNew, setIsNew] = useState(false);
    const [vals, setVals] = useState({ type: '', values: [] });
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState([]);
    const [data, setData] = useState({
        _id: _cid,
        type: '',
        when: 0,
        scope: '',
        enable: true,
        app_name: '',
        app_version: '',
        version_condition: ''
    });
    const [immediately, setImmediately] = useState(true);
    const [apps, setApps] = useState([]);

    const types = [
        { value: "BROWSER", label: t("Open Web-browser") },
        { value: "MODAL", label: t("Central modal") },
        { value: "WINDOWS_NOTIFY", label: t("Windows notification") },
        { value: "RAYTEAMS_ALARM", label: t("RAYTeams Alarm") },
        { value: "EMAIL", label: t("Send email") },
        { value: "KILL_APP", label: t("Kill App") }
    ];

    useEffect(() => {
        dispatch({
            type: ProgramConstant.SET_PAGE,
            page: { code: "notifications", view: "summary", title: "Notifications", small: t("Notifications 정보"), options: [] }
        });

        getData();
    }, []);

    useEffect(() => {
        if (immediately)
            setData({ ...data, when: 0 });
    }, [immediately]);

    useEffect(() => {
        console.log('data', data);
    }, [data]);

    useEffect(() => {
        setApps(applications.map(x => {
            return {
                value: x.name,
                label: x.titleText
            }
        }));
    }, [applications]);

    useEffect(() => {
        if (data.app_name)
            dispatch(ApplicationAction.GetApplicationVersions(data.app_name))
    }, [data.app_name]);


    const createNew = () => {
        setEdit(true);
    }

    const getData = async () => {
        try {
            const ret = await fetchData("rayteams-manager-contents", { _id: { ComparisonOperator: "EQ", AttributeValueList: [_cid] } });

            setTitle(ret.Items.find(x => x.sk === 'info')?.title);
            setContent(ret.Items.filter(x => x.sk.startsWith("lang")).map((x, idx) => {
                return {
                    ...x,
                    selected: idx === 0 ? true : false
                }
            }));
            dispatch(ApplicationAction.GetApplications());

            const retScope = await fetchData("rayteams-manager-notify", { _id: { ComparisonOperator: "EQ", AttributeValueList: [_cid] } });

            if (retScope.Count > 0) {
                const items = retScope.Items[0];

                setData(items);
                setVals({
                    type: items.scope,
                    values: items.scope !== 'a' ? items.scope.split(':')[1].split(',') : []
                });
                setImmediately(items.when === 0);

                setIsNew(false);
            } else {
                setIsNew(true);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const Save = () => {
        const usersub = localStorage.getItem("usersub");

        if (isNew) {
            insertData("rayteams-manager-notify", { ...data, created: new Date().getTime(), creator: usersub }).then(ret => {
                setVals({ type: ret.scope, values: ret.scope === 'a' ? [] : ret.scope.split(":")[1].split(",") });
                setIsNew(false);
            })
        }
        else {
            const payload = {
                type: data.type,
                url: data.type === "BROWSER" ? data.url : '',                
                notshow: data.type === "MODAL" ? data.notshow ? data.notshow : {} : {},
                modal_size : data.type === "MODAL" ? data.modal_size ? data.modal_size : {} : {},
                app_name: data.app_name,
                app_version: data.app_version,
                version_condition: data.version_condition,
                when: data.when,
                scope: data.scope,
                enable: data.enable,
                ishomepage: data?.ishomepage || false,
                updated: new Date().getTime(),
                updater: usersub
            };

            if(data.kill_app) 
                payload.kill_app = data.kill_app;

            updateData("rayteams-manager-notify", { _id: _cid }, payload).then(ret => {
                setVals({ type: ret.scope, values: ret.scope === 'a' ? [] : ret.scope.split(":")[1].split(",") });
            });
        }

        setEdit(false);
        RTAudit({ lvl: 4, msg: 'Save Notification'}, JSON.stringify({ subject: data }));
    }

    const clickEdit = (c) => {
        setVals(c);
        setEdit(true);
    }

    const changeData = (c) => {                
        setData({ ...data, scope: c });
    }

    const selectTab = (selectedIdx) => {
        const result = content.map((x, idx) => {
            return {
                ...x,
                selected: idx === selectedIdx ? true : false
            }
        });

        setContent(result);
    }

    return (
        <div className='p-4'>
            <div className='card'>
                <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                        <h3 class="mb-0">
                            <a href="#">{title}</a>
                        </h3>
                        <div class="d-flex align-items-center">
                            {!edit &&
                                <MLButton options={{
                                    code: "notify-edit",
                                    color: COLORS.PRIMARY, 
                                    action: () => setEdit(!edit),
                                    disabled: (edit && data.scope !== 'a' && !data.scope.split(':')[1]) ? true : false
                                }} css='me-2' />
                            }
                            {edit &&
                                <MLButton options={{
                                    label: t("Save"),
                                    color: COLORS.PRIMARY, 
                                    action: () => Save(),
                                    disabled: (edit && data.scope !== 'a' && !data.scope.split(':')[1]) ? true : false
                                }} css='me-2' />
                            }
                            <MLButton options={{ label: t("List"), color: COLORS.DARK, action: () => history.push("/Notifications") }} />
                        </div>
                    </div>
                </div>
                <div className='p-4'>
                    {
                        edit &&
                        <div className='row'>
                            <div className='mb-4'>
                                <h5>{t("Notifications Type")}</h5>
                                    <MLComboSingle options={{
                                        list: types,
                                        value: t(data.type)
                                    }} handleChanged={(e) => setData({ ...data, type: e.target.value })} />

                                    {
                                        data.type === "MODAL" &&
                                        <>
                                            <label class="fw-bolder me-3 mb-1">앱 종료</label>
                                            <div>
                                                <MLToggle
                                                    options={{ label: data.kill_app ? '종료함' : '종료하지 않음', inline: true, value: data.kill_app || false }}
                                                    handleChanged={(e) => { setData({ ...data, kill_app: e.target.value }); }}
                                                />
                                            </div>

                                            <label class="fw-bolder me-3 mb-1">보지않기 옵션</label>
                                            <div>
                                                <MLToggle
                                                    options={{ label: data.notshow?.enable ? '사용' : '미사용', inline: true, value: data.notshow?.enable || false }}
                                                    handleChanged={(e) => { setData({ ...data, notshow: { enable: e.target.value } }); }}
                                                />
                                            </div>

                                            {
                                                data.notshow?.enable &&
                                                <div className='d-flex align-items-center mb-1'>
                                                    <MLText
                                                        options={{ value: data.notshow?.days }}
                                                        style={{ width: "60px", textAlign: 'center' }}
                                                        handleChanged={(e) => setData({ ...data, notshow: { enable: true, days: e.target.value } })}
                                                        css='mb-0'
                                                    /> <div className='ms-1'>일간 보지않음.</div>
                                                </div>
                                            }

                                            <label class="fw-bolder me-3 mb-1">창크기</label>
                                            <div className='d-flex align-items-center'>
                                                <div className='ms-1'>Width&nbsp;</div>
                                                <MLText
                                                    options={{ value: data.modal_size?.w }}
                                                    style={{ width: "65px", textAlign: "center" }}
                                                    handleChanged={(e) => setData({ ...data, modal_size: { ...data.modal_size, w: e.target.value } })}
                                                    css='mb-0'
                                                />
                                                <div className='ms-1'>Height&nbsp;</div>
                                                <MLText
                                                    options={{ value: data.modal_size?.h }}
                                                    style={{ width: "65px", textAlign: "center" }}
                                                    handleChanged={(e) => setData({ ...data, modal_size: { ...data.modal_size, h: e.target.value } })}
                                                    css='mb-0'
                                                />
                                            </div>

                                        </>
                                    }

                                    {
                                        data.type === "BROWSER" &&
                                        <>
                                            <label class="fw-bolder me-3 mb-1">URL</label>
                                            <MLText options={{ value: data?.url }} handleChanged={(e) => setData({ ...data, url: e.target.value })} />
                                        </>
                                    }
                            </div>

                            <div className='mb-4'>
                                <h5>{t("Software & Version")}</h5>
                                    <label class="fw-bolder me-3 mb-1">{t("Software")}</label>
                                    <MLComboSingle
                                        options={{ list: apps || [], name: 'apps', value: data.app_name }}
                                        handleChanged={(e) => setData({ ...data, app_name: e.target.value, app_version: '' })}
                                    />
                                    <label class="fw-bolder me-3 mb-1">{t("Version")}</label>
                                    <MLComboSingle options={{
                                        list: applicationVersions.map(x => {
                                            return {
                                                label: x.version,
                                                value: x.version
                                            }
                                        }), value: data.app_version
                                    }} handleChanged={(e) => setData({ ...data, app_version: e.target.value })} />

                                    <MLRadioButton
                                        options={{
                                            list: [{ label: t('이상'), value: 'UP' }, { label: t('이하'), value: 'DN' }, { label: t('선택된 버전'), value: 'EQ' }],
                                            value: data.version_condition
                                        }}
                                        handleChanged={(e) => setData({ ...data, version_condition: e.target.value })}
                                    />
                            </div>

                            <div className='mb-4'>
                                <h5>When</h5>
                                <MLToggle options={{ label: t('즉시'), inline: true, value: immediately }} handleChanged={() => setImmediately(!immediately)} />
                                {
                                    !immediately &&
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        selected={data.when}
                                        onChange={e => setData({ ...data, when: e.getTime() })}
                                        className="form-control"                                        
                                        showTimeSelect={true}
                                        value={data.when}
                                    />
                                }
                            </div>
                            <div className='mb-4'>
                                <h5>{t("Scope")}</h5>
                                <MLScope handleChanged={e => changeData(e)} edit={edit} value={vals} />
                            </div>

                            <div className='mb-4'>
                                <h5>사용여부</h5>
                                <MLToggle options={{ label: data.enable ? "사용" : "미사용", inline: true, value: data.enable || false }} handleChanged={(e) => { setData({ ...data, enable: e.target.value }); }} />
                            </div>
                            <div>
                                <h5>홈페이지 사용여부</h5>
                                <MLToggle options={{ label: data.enable ? "사용" : "미사용", inline: true, value: data?.ishomepage || false }} handleChanged={(e) => { setData({ ...data, ishomepage: e.target.value })}} ></MLToggle>
                            </div>
                        </div>
                    }
                    {
                        !edit &&
                        <div className='rows'>
                            <div className='mb-4'>
                                <h5>{t("Notifications Type")}</h5>
                                {types.find(x => x.value === data.type)?.label}
                                {
                                    data.type == "BROWSER" &&
                                    <>&nbsp;(<a href={'http://' + data.url} target="_blank">{data.url}</a>)</>
                                }
                                {
                                    (data.type === "MODAL" && data.notshow?.enable) &&
                                    <>&nbsp;(보지않기 옵션 : {data.notshow?.days}일간 보지 않음)</>
                                }
                                {
                                    (data.type === "MODAL" && data?.kill_app) &&
                                    <>&nbsp;(앱 종료 : 사용)</>
                                }
                                {
                                    (data.type === "MODAL" && data?.modal_size) &&
                                    <>&nbsp;(너비 : {data.modal_size?.w}, 높이 : {data.modal_size?.h})</>
                                }
                            </div>

                            <div className='mb-4'>
                                <h5>{t("Software & Version")}</h5>
                                {applications.find(x => x.name === data.app_name)?.title}
                                {` (${data.app_version}`}
                                {
                                    (data.version_condition === 'UP' && ' 이상' ||
                                        data.version_condition === 'DN' && ' 이하' ||
                                        data.version_condition === 'EQ' && '') + ')'
                                }
                            </div>

                            <div className='mb-4'>
                                <h5>{t("When")}</h5>
                                <div>{data.when === 0 ? '즉시' : moment(data.when).format("YYYY-MM-DD HH:mm")}</div>
                            </div>
                            <div className='mb-4'>
                                <h5>{t("Scope")}</h5>
                                <div><MLScope edit={edit} value={vals} clickEdit={clickEdit} /></div>
                            </div>
                            <div className='mb-4'>
                                <h5>{t("사용여부")}</h5>
                                <div>{data.enable ? "사용" : "미사용"}</div>
                            </div>
                            <div className='mb-4'>
                                <h5>{t("RAYTeams.com 사용여부")}</h5>
                                <div>{data?.ishomepage ? "사용": "미사용"}</div>
                            </div>
                        </div>
                    }

                    {
                        (data.type !== 'BROWSER' && data.type !== "KILL_APP") &&
                        <div>
                            <ul className="nav nav-tabs nav-stretch border-top mt-4 pt-2" style={{ borderRadius: "0" }}>
                                {
                                    content.map((x, idx) => {
                                        return (
                                            <li className="nav-item" key={idx} onClick={() => selectTab(idx)}>
                                                <Link to="#" className={`nav-link ${x.selected ? ' active' : ''}`}>
                                                    {LANGUAGE.find(y => y.code === x.sk.replace('lang:', '')).label}
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <div className='pt-3'>
                                <h3 className='mb-1'>{content.find(x => x.selected)?.title}</h3>
                            </div>
                            <div className='pt-4' dangerouslySetInnerHTML={{ __html: content.find(x => x.selected)?.content }}></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapState = (state) => {
    const applications = state.ApplicationReducer.applications;
    const applicationVersions = state.ApplicationReducer.applicationVersions;
    return { applications, applicationVersions };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState, mapDispatch)(NotificationsInfo);
