import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../constants/FromInputs';
import { MLButton } from '../components/common/MLButton';
import { MLForm } from '../components/common/MLForm';
import { Loader } from '../components/common/Loader';
import { ProgramConstant } from '../redux/reducers';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../libs/audit';
import { UploadS3 } from '../libs/s3';
import { AlertConstant } from '../redux/reducers';

const getEnv = () => {
	if (location.href.includes("localhost") || location.href.includes("127.0.0.1")) {
		return "dev-local";
	} else if (location.href.includes("raydevelop")) {
		return "development";
	}
	return "production";
};

const initialAdminData = {
	reusePassword: 'N',
	passwordChangePeriod: 90,
	reusePasswordLimit: 3,
};

const initialUserData = {
	reusePassword: 'N',
	passwordChangeNotification: 90,
	changeNext: 'Y',
	nextChangeNotificationPeriod: 90,
	reusePasswordLimit: 3,
};

const AdminPwSecurity = ({ group, adminUsers, user, loginuser }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['translation']);
	const { _id } = useParams();

	const [data, setData] = useState({});
	const [adminData, setAdminData] = useState(initialAdminData);
	const [userData, setUserData] = useState(initialUserData);
	const [loading, setLoading] = useState(false);
	const [tab, setTab] = useState("pwmanage");
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		dispatch({
			type: ProgramConstant.SET_PAGE,
			page: {
				code: "security",
				title: "Security",
				small: t("보안관리"),
				options: []
			}
		});
	}, [_id, loginuser, dispatch, t]);

	useEffect(() => {
		getConfig();
	}, []);

	useEffect(() => {
		if (!initialized && Object.keys(data).length > 0) {
			setAdminData(data.adminPwSecurity || initialAdminData);
			setUserData(data.userPwSecurity || initialUserData);
			setInitialized(true);
		}
	}, [initialized, data]);

	const getConfigPath = () => {
		const now = new Date().getTime();
		if (getEnv() === "production") {
			return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + "ray" + "/config.json" + `?l=${now}`;
		} else {
			return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + "ray" + "/config.json" + `?l=${now}`;
		}
	}

	const getConfig = async () => {
		try {
			const conf = await axios.get(getConfigPath());
			const savedData = conf?.data || {};
			setData(savedData);
			setAdminData(savedData.adminPwSecurity || initialAdminData);
			setUserData(savedData.userPwSecurity || initialUserData);
			dispatch({ type: ProgramConstant.SET_SERVICE, item: savedData });
		} catch (error) {
			setData({});
		}
	};

	const update = async (updatedData, type) => {
		try {
			const conf = await axios.get(getConfigPath());
			const savedData = conf?.data || {};
			let newData = { ...savedData };

			if (type === 'admin') {
				newData.adminPwSecurity = updatedData;
				setAdminData(updatedData);
			} else if (type === 'user') {
				newData.userPwSecurity = updatedData;
				setUserData(updatedData);
			}

			const bucket = getEnv() === "production" ? 'ray-data' : 'ray-data-development';


			await UploadS3(
				bucket,
				`rayrnd/rayteams-service/ray/config.json`,
				newData
			);

			setData(newData);

			const auditMsg = type === 'admin' ? 'Updated Admin Password Security' : 'Updated User Password Security';
			RTAudit({ lvl: 9, msg: `[${loginuser.name}] ${auditMsg}` }, JSON.stringify(updatedData));
			dispatch({ type: AlertConstant.NOTICE, msg: t('비밀번호 설정이 적용되었습니다.'), show: true });
		} catch (error) {
			console.error("Error updating config:", error);
		}
	};

	const updateAdminPwSecurity = () => {
		const updatedData = {
			reusePassword: adminData.reusePassword,
			passwordChangePeriod: adminData.passwordChangePeriod,
		};
		if (adminData.reusePassword === 'N') {
			updatedData.reusePasswordLimit = adminData.reusePasswordLimit;
		}
		update(updatedData, 'admin');
	};

	const updateUserPwSecurity = () => {
		const updatedData = {
			reusePassword: userData.reusePassword,
			passwordChangeNotification: userData.passwordChangeNotification,
			changeNext: userData.changeNext,
		};
		if (userData.reusePassword === 'N') {
			updatedData.reusePasswordLimit = userData.reusePasswordLimit;
		}
		if (userData.changeNext === 'Y') {
			updatedData.nextChangeNotificationPeriod = userData.nextChangeNotificationPeriod;
		}
		update(updatedData, 'user');
	};

	const handleAdminDataChange = (data) => {
		setAdminData({
			...data,
			passwordChangePeriod: data.passwordChangePeriod || 90,
			reusePasswordLimit: data.reusePasswordLimit || 3,
		});
	};

	const handleUserDataChange = (data) => {
		setUserData({
			...data,
			passwordChangeNotification: data.passwordChangeNotification || 90,
			nextChangeNotificationPeriod: data.nextChangeNotificationPeriod || 90,
			reusePasswordLimit: data.reusePasswordLimit || 3,
		});
	};

	const userControls = [
		{
			type: INPUTS.COMBO,
			options: {
				label: t('비밀번호 변경 알림 주기'),
				name: 'passwordChangeNotification',
				list: [
					{ value: 30, label: '30일마다' },
					{ value: 60, label: '60일마다' },
					{ value: 90, label: '90일마다' },
					{ value: 120, label: '120일마다' }
				],
				value: userData.passwordChangeNotification,
			}
		},
		{
			type: INPUTS.RADIOBUTTON,
			options: {
				label: t("이전에 사용한 비밀번호 재사용"),
				name: "reusePassword",
				list: [
					{ value: 'N', label: t('허용하지 않음') },
					{ value: 'Y', label: t('허용') }
				],
			},
		},

		{
			type: userData.reusePassword === 'Y' ? INPUTS.HIDDEN : INPUTS.COMBO,
			options: {
				label: t("이전에 사용한 비밀번호 반복 사용 제한"),
				name: "reusePasswordLimit",
				required: true,
				list: [
					{ value: 1, label: t('이전 1개까지 사용 불가') },
					{ value: 2, label: t('이전 2개까지 사용 불가') },
					{ value: 3, label: t('이전 3개까지 사용 불가') },
					{ value: 4, label: t('이전 4개까지 사용 불가') },
					{ value: 5, label: t('이전 5개까지 사용 불가') }
				],
				value: userData.reusePasswordLimit,
			},
		},

		{
			type: INPUTS.RADIOBUTTON,
			options: {
				label: t("다음에 변경 가능"),
				name: "changeNext",
				value: userData.changeNext,
				list: [
					{ value: 'Y', label: t('허용') },
					{ value: 'N', label: t('허용하지 않음') }
				],
			},
		},
		{
			type: userData.changeNext === 'N' ? INPUTS.HIDDEN : INPUTS.COMBO,
			options: {
				label: t('다음에 변경 시 다음 알림 주기'),
				name: 'nextChangeNotificationPeriod',
				list: [
					{ value: 7, label: '7일 후 다시 알림' },
					{ value: 10, label: '10일 후 다시 알림' },
					{ value: 30, label: '30일 후 다시 알림' },
					{ value: 60, label: '60일 후 다시 알림' },
					{ value: 90, label: '90일 후 다시 알림' },
				],
				value: userData.nextChangeNotificationPeriod,
			}
		},
	].filter(Boolean);

	const adminControls = [
		{
			type: INPUTS.COMBO,
			options: {
				label: t('비밀번호 변경 주기'),
				name: 'passwordChangePeriod',
				list: [
					{ value: 30, label: '30일마다' },
					{ value: 60, label: '60일마다' },
					{ value: 90, label: '90일마다' },
					{ value: 120, label: '120일마다' }
				],
				value: adminData.passwordChangePeriod,
			}
		},
		{
			type: INPUTS.RADIOBUTTON,
			options: {
				label: t("이전에 사용한 비밀번호 재사용"),
				name: "reusePassword",
				list: [
					{ value: 'N', label: t('허용하지 않음') },
					{ value: 'Y', label: t('허용') }
				],
			},
		},
		{
			type: adminData.reusePassword === 'Y' ? INPUTS.HIDDEN : INPUTS.COMBO,
			options: {
				label: t("이전에 사용한 비밀번호 반복 사용 제한"),
				name: "reusePasswordLimit",
				list: [
					{ value: 1, label: t('이전 1개까지 사용 불가') },
					{ value: 2, label: t('이전 2개까지 사용 불가') },
					{ value: 3, label: t('이전 3개까지 사용 불가') },
					{ value: 4, label: t('이전 4개까지 사용 불가') },
					{ value: 5, label: t('이전 5개까지 사용 불가') }
				],
				value: adminData.reusePasswordLimit,
			},
		},
	];

	return (
		<div className='p-4'>
			{loading && <Loader />}
			{!loading && (
				<>
					<div className='card'>
						{loginuser.manager ? (
							<>
								<div className='card-header py-0'>
									<ul className="nav nav-tabs nav-stretch border-top">
										<li className="nav-item cursor-pointer" onClick={() => setTab("pwmanage")}>
											<a className={`nav-link ${tab === 'pwmanage' ? 'active' : ''}`}>
												<span className='nav-text'>{t("비밀번호 관리")}</span>
											</a>
										</li>
									</ul>
								</div>
								<div className=''>
									{tab === "pwmanage" && (
										<div className='p-4'>
											{/* For Manager */}
											<div>
												<h4 className='pt-2'>{t("For RAYTeams Manager")}</h4>
												<MLForm controls={adminControls} data={adminData} onChanged={handleAdminDataChange} nocard />
												<div className='mt-3 text-end'>
													<MLButton
														options={{
															label: t("Reset Defaults"),
															color: COLORS.LIGHT,
															action: () => setAdminData(initialAdminData)
														}}
														css='me-2'
													/>
													<MLButton
														options={{
															label: t("Apply"),
															color: COLORS.PRIMARY,
															action: updateAdminPwSecurity
														}}
													/>
												</div>
												<hr className='my-5' />
											</div>
											{/* For Users */}
											<div>
												<h4>{t("For RAYTeams Client and Homepage users")}</h4>
												<MLForm controls={userControls} data={userData} onChanged={handleUserDataChange} nocard />
												<div className='mt-3 text-end'>
													<MLButton
														options={{
															label: t("Reset Defaults"),
															color: COLORS.LIGHT,
															action: () => setUserData(initialUserData)
														}}
														css='me-2'
													/>
													<MLButton
														options={{
															label: t("Apply"),
															color: COLORS.PRIMARY,
															action: updateUserPwSecurity
														}}
													/>
												</div>
											</div>
										</div>
									)}
								</div>
							</>
						) : (
							<div className='card-body'>
								<h5 className='text-center py-5'>{t("권한이 없습니다")}</h5>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { adminUsers, user, loginuser } = state.AuthReducer;
	return { adminUsers, user, loginuser };
};

export default connect(mapStateToProps)(AdminPwSecurity);
