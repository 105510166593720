import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { UploadFileS3, UploadS3 } from "../../libs/s3";
import { MLForm } from "../common/MLForm";
import { ProgramConstant } from "../../redux/reducers";
import { COUNTRIES } from "../../constants/Country";
import { LANGUAGE, ORTHO_FORM_KEY_SET } from "../../constants/RMConstants";
import { RTAudit } from "../../libs/audit";
const VIEW_TYPE = {
    DEFAULT: 'default',
    LANGKEYS: 'lang_keys',
    TRANS: 'trans',
    TRANSUSER : 'transuser'
};

const getEnv = () => {
    if(location.href.indexOf("localhost") > -1 || location.href.indexOf("127.0.0.1") > -1){
        return "dev-local";
    }else if(location.href.indexOf("raydevelop") > -1){
        return "development";
    }
    return "production";
}

const ServiceConfigBasic = ({ UpdateApplication }) => {
	const [t] = useTranslation(['translation']);
    const { _id = process.env.REACT_APP_COMPANY } = useParams();
	const dispatch = useDispatch();
    const partnerKey = localStorage.getItem('partnerKey') || '';
    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const [newitem, setNewItem] = useState({
        key : "",
        value : "",
    });

    useEffect(() => {
        getConfig();
    }, []);

    const getConfigPath = () => {
		const now = new Date().getTime();
        if(getEnv() === "production"){
            return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json" + `?l=${now}`;
        }else{
            return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/"+ _id + "/config.json" + `?l=${now}`;
        }
    }

    const getConfig = async () => {
        try{
            const conf = await axios.get(getConfigPath());
            setData(conf?.data || {});
            dispatch({ type : ProgramConstant.SET_B2B_SERVICE, item : conf?.data || {} });
        }catch{
            setData({});
        }
    }

    const update = async () => {
        const conf = await axios.get(getConfigPath());
        const ori_config = conf?.data || {};
        const dt = new Date().getTime();
        const bucket = getEnv() === "production" ? 'ray-data' : 'ray-data-development';
        let newData = {...data};
        if(data.servicefavicon?.path){
			const successUploadedFavicon = await UploadFileS3(bucket, data.servicefavicon, 'rayrnd/rayteams-service/' + _id + '/fav-' + dt +'.' + data.servicefavicon.path.split('.').pop());
            if (successUploadedFavicon){
                newData = {
                    ...newData, 
                    servicefavicon : 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
                        + _id 
                        + '/fav-' + dt +'.' + data.servicefavicon.path.split('.').pop()
                }
            }
        }
        if(data.servicelogo?.path){
			const successUploadedLogo = await UploadFileS3(bucket, data.servicelogo, 'rayrnd/rayteams-service/' + _id + '/logo-' + dt +'.' + data.servicelogo.path.split('.').pop())
            if(successUploadedLogo){
                newData = {
                    ...newData, 
                    servicelogo : 'https://' + bucket + '.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/' 
                        + _id 
                        + '/logo-' + dt +'.' + data.servicelogo.path.split('.').pop()
                }
            }
        }
        await UploadS3(
            getEnv() === "production" ?  "ray-data" : "ray-data-development",
            "rayrnd/rayteams-service/"+ _id + "/config.json",
            {...ori_config, ...newData}
        );
        await getConfig();
        RTAudit({ lvl: 4, msg: 'Update Basic Configuration'}, JSON.stringify({ subject: data }));

        setEdit(false);
       
    }

    const controls = [
		{
			type : INPUTS.SINGLEFILE,
			options : {
				label : t('로고'),
				name : 'servicelogo',
				value : data.servicelogo,
                noment : true
			}
		},
		{
			type : INPUTS.SINGLEFILE,
			options : {
				label : t('파비콘'),
				name : 'servicefavicon',
				value : data.servicefavicon,
                noment : true
			}
		},
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('담당자 Email'),
				name : 'email',
				hint : 'Ray에서 연락 가능한 담당자 Email(사용자에게 노출 안됨)',
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('담당자 연락처'),
				name : 'tel',
				hint : 'Ray에서 연락 가능한 담당자 연락처(사용자에게 노출 안됨)',
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('관리자 페이지 타이틀'),
				name : 'managertitle',
				hint : 'RAYTeams 관리자 페이지 Title',
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('서비스 페이지 타이틀'),
				name : 'rayteamstitle',
				hint : 'RAYTeams 서비스 Title',
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('회사 홈페이지 주소'),
				name : 'homepageurl',
				placeholder : 'https:// or http://',
				hint : '사용자에게 노출되는 회사 공식 홈페이지',
			}
		},
		{
			type : INPUTS.EMAIL,
			options : {
				label : t('고객 센터 Email'),
				name : 'supportemail',
				hint : '사용자에게 노출되는 Email',
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('고객 센터 전화번호'),
				name : 'supporthp',
				hint : '사용자가 연락 가능한 고객사의 전화번호',
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('서비스 지역'),
				name : 'servicescountryall',
				truemsg : '전세계',
				falsemsg : '일부',
			}
		},
		{
			type : data.servicescountryall ? INPUTS.HIDDEN : INPUTS.COMBOMULTI,
			options : {
				label : t('서비스 국가'),
				name : 'servicescountry',
				list : COUNTRIES.map(x => ({ value : x.countryCode, label : x.name })),
				hint : '사용자의 가입 가능 국가',
			}
		},
		{
			type : INPUTS.COMBOMULTI,
			options : {
				label : t('지원 언어'),
				name : 'languages',
				list : LANGUAGE.map(x => ({ value : x.code, label : x.label })),
				hint : '사용자에게 제공하는 지원 언어 목록',
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('공지 사항 사용 여부'),
				name : 'noticenotuse',
				falsemsg : t('사용'),
				truemsg : t('사용 안함'),
				hint : '사용자에게 공지사항 노출/사용 여부',
			}
		},
		{
			type : _id === "graphy" ? INPUTS.COMBOMULTI : INPUTS.HIDDEN,
			options : {
				label : t('교정 폼 숨김 목록'),
				name : 'hideorthoforms',
				list : ORTHO_FORM_KEY_SET,
				hint : '교정 폼에서 숨길 항목',
			}
		},
		// {
		// 	type : INPUTS.TOGGLE,
		// 	options : {
		// 		label : t('FAQ 사용 여부'),
		// 		name : 'faqnotuse',
		// 		falsemsg : t('사용'),
		// 		truemsg : t('사용 안함'),
		// 		hint : '사용자에게 제공한 FaQ 사용 여부',
		// 	}
		// },
		{
			type : SPAN.VERTICAL,
			options : {
				size : 20,
			}
		},
    ]

    const controlsad = [
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('회원 가입 승인 기능 사용 여부'),
				name : 'approvedaccountonly',
				falsemsg : t('회원 가입 후 바로 서비스 사용이 가능합니다.'),
				truemsg : t('회원 가입 후 관리자 승인 이후에만 서비스 사용이 가능합니다.'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('치과 계정의 맴버 추가 기능 사용 여부'),
				name : 'allowinviteclinicmember',
				falsemsg : t('치과 계정은 맴버를 추가할 수 없습니다.'),
				truemsg : t('치과의 권한이 있는 계정은 맴버를 추가할 수 있습니다.'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('외부 결제 기능 사용 여부'),
				name : 'useouterpayment',
				falsemsg : t('외부 결제 기능을 사용하지 않습니다.'),
				truemsg : t('외부 결제 기능을 사용합니다.'),
			}
		},
		{
			type : !data.useouterpayment ? INPUTS.HIDDEN : INPUTS.TEXT,
			options : {
				label : t('외부 결제 연결 URL'),
				name : 'outerpaymenturl',
				hint : '사용자가 결제 버튼을 클릭했을때 연결되는 URL을 입력해주세요',
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('Apps 메뉴 노출 제한'),
				name : 'hideapps',
				falsemsg : t('Apps 메뉴를 노출합니다.'),
				truemsg : t('Apps 메뉴를 노출하지 않습니다.'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('케이스 노출'),
				name : 'showcase',
				falsemsg : t('전체 노출'),
				truemsg : t('담당하고 있는 케이스만 열람'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('디자인 드래프트'),
				name : 'designdraft',
				falsemsg : t('사용안함'),
				truemsg : t('사용함'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('Multiple Treatment Plan 사용'),
				name : 'mutipletp',
				falsemsg : t('사용안함'),
				truemsg : t('사용함'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('Remake 사용 여부'),
				name : 'remake',
				falsemsg : t('사용안함'),
				truemsg : t('사용함'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('Refinement 사용 여부'),
				name : 'refinement',
				falsemsg : t('사용안함'),
				truemsg : t('사용함'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('Synced data 보기'),
				name : 'showsynceddata',
				falsemsg : t('사용안함'),
				truemsg : t('사용함'),
			}
		},
		{
			type : INPUTS.TOGGLE,
			options : {
				label : t('매니저 싸이트 회원정보 확인 유무'),
				name : 'showalludata',
				falsemsg : t('매니저 사이트에서 회원 정보를 모두 표시하지 않습니다.'),
				truemsg : t('매니저 사이트에서 회원 정보를 모두 표시합니다.'),
			}
		},
		{
			type : INPUTS.TEXT,
			options : {
				label : t('Sentry DSN'),
				name : 'sentrydsn',
				hint : '개발자만 편집해주세요.',
			}
		},
    ]

    return <div className='p-4'>
        <MLForm controls={controls} readonly={!edit} data={data} nocard={true} onChanged={(e) => { setData({...data, ...e}) }} />
		{(!process.env.REACT_APP_COMPANY || process.env.REACT_APP_COMPANY === 'ray') && <>
			<div className="d-flex justify-content-between my-2 mt-4">
				<h3>{t('Advanced')}</h3>
			</div>
			<MLForm controls={controlsad} readonly={!edit} data={data} nocard={true} onChanged={(e) => { setData({...data, ...e}) }} />
		</>}
        <div className="d-flex align-items-center justify-content-end gap-2">
            {!edit && <MLButton options={{
				code: "ptn-basicedit",
                label : t('편집'), 
                color : COLORS.PRIMARY,
                action : () => setEdit(!edit)
            }} />}
			{edit && <>
				<MLButton options={{
					label : t('적용'), 
					color : COLORS.PRIMARY,
					action : () => update()
				}} />
				<MLButton options={{
					label : t('취소'), 
					color : COLORS.LIGHT,
					action : () => setEdit(!edit)
				}} />
			</>}
        </div>
    </div>
}

export default ServiceConfigBasic;
