import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BUTTONS, COLORS, INPUTS, SPAN } from '../../constants/FromInputs';
import { MLButton } from '../common/MLButton';
import { MLComboSingle, MLText, MLNumber, MLDate, MLTextArea, MLToggle } from '../common/MLInput';
import { fetchData, idData, putData, queryData, updateData_withExpression } from '../../libs/db';
import { LicenseConstant, AppCategoryConstant } from '../../redux/reducers';
import { MLPanelLoading } from '../common/MLPanel';
import { RMLICENSETYPE, RMLICENSETYPE_MOCKUP } from '../../constants/RMConstants';
import { RTButton } from '../../libs/buttons';
import { useTranslation } from 'react-i18next';
import { RTAudit } from '../../libs/audit';
import { getApiUrl } from '../../redux/reducers/AuthReducer';
import { JWT } from '../../config';

const RMModalAddPoolLicense = ({ modalData, managedApp, callbackClose }) => {
	const dispatch = useDispatch();
	const [t] = useTranslation(['translation']);
	const [ addD, setAddD ] = useState({ licensecount: 0, licensetype: "normal" });
    const { _id } = useParams("");
    const [loading, setLoading] = useState(false);
	const { v4 } = require("uuid");
	const [licenseType, setLicenseType] = useState([]);
	const [isDueDate, setIsDueDate] = useState(false);
	const [isNoReSub, setIsNoReSub] = useState(false);
	const [isAutoSub, setIsAutoSub] = useState(false);
	const [isB2b, setIsB2b] = useState(false);

	const RMLCTYPES = _id && _id.includes("DigitalSmileDesignInAppExportMockUp") ? RMLICENSETYPE_MOCKUP : RMLICENSETYPE.filter(f => f.value != "annual");
	const partner = process.env.REACT_APP_COMPANY || 'ray';

	useEffect(() => {
		if(modalData.show){
			!managedApp && getData();
			getTypePeriod();
		}
	}, [modalData.show]);

	const getTypePeriod = async () => {
		setLoading(true);

		let params = {
			TableName: "rayteams-license",
			KeyConditionExpression: "#_id = :_id and begins_with(#sk, :sk)",
			ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
			ExpressionAttributeValues: { ":_id": _id, ":sk": "type:" }
		}

		if (partner != "ray") {
			params = {
				...params,
				FilterExpression: "#b2b = :b2b",
				ExpressionAttributeNames: {
					...params.ExpressionAttributeNames,
					"#b2b": "b2b"
				},
				ExpressionAttributeValues: {
					...params.ExpressionAttributeValues,
					":b2b": partner
				}
			}
		}

		const sData = {
			type: "query",
			b2b: partner,
			params
		}

		try{
			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
			console.log("getTypePeriod : ==========", ret.data?.data.filter(f => partner == "ray" ? !(!!f.sk.split(":")[2]) : !!f.sk.includes(partner)));
			setLicenseType(ret.data?.data);
			setAddD({
				...addD, 
				typePeriod : parseInt(ret.data?.data.find(f => f.licensetype === addD.licensetype)?.period) || 0, 
				dueDate : ret.data?.data.find(x => x.licensetype === addD.licensetype)?.dueDate || 0 
			});
		}catch(err){
			console.log(err);
		}
		setLoading(false);
	};

	const getData = async () => {
		setLoading(true);
		let params = {
			TableName: "rayteams-license",
			KeyConditionExpression: "#_id = :_id and #sk = :sk",
			ExpressionAttributeNames: { "#_id": "_id", "#sk": "sk" },
			ExpressionAttributeValues: { ":_id": _id, ":sk": "info" }
		}

		const sData = {
			type: "query",
			b2b: partner,
			params
		}
		
		try {
			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
			dispatch({ type: AppCategoryConstant.GET_MANAGED_APP, item: ret.data?.data[0]});

		} catch (e) {
			console.log(e);
		}
		setLoading(false);
	}

	const getData_pool = async () => {
		setLoading(true);
		try {
			let params = {
				TableName: "rayteams-license",
				FilterExpression: "#_id = :_id",
				ExpressionAttributeNames: { "#_id": "_id" },
				ExpressionAttributeValues: { ":_id": _id }
			}
	
			if (partner != "ray") {
				params = {
					...params,
					FilterExpression: params.FilterExpression + " and #b2b = :b2b",
					ExpressionAttributeNames: {
						...params.ExpressionAttributeNames,
						"#b2b": "b2b"
					},
					ExpressionAttributeValues: {
						...params.ExpressionAttributeValues,
						":b2b": partner
					}
				}
			}
			const sData = {
				type: "scan",
				b2b: partner,
				params
			};

			const ret = await axios.post(getApiUrl("license") + "/license/getlicenseinfo", {data: sData}, JWT());
			dispatch({ type: LicenseConstant.GET_ALL_POOL, items: ret.data?.data });
		} catch (err) {
			console.log(err)
		}
		setLoading(false);
	};

    const save = async () => {
		setLoading(true);
		console.log("add LIcense : ===", addD);
		const list = addD?.lcs?.split(/\n/);
		if(managedApp?.isray) {
			console.log(addD);
			for (let i = 0; i < addD.licensecount; i++) {
				let item = {
					_id : _id,
					sk : "pl:" + addD.licensetype + ":" + v4(),
					lckey : v4(),
					deadline : !!addD.regideadline ? addD.regideadline : 99999999999999,
					expired : 99999999999999,
					period : addD.typePeriod == 0 ? (addD?.period || 0) : addD.typePeriod,
					used : "",
					created : new Date().getTime(),
				}
				if (isB2b && addD?.b2b?.length > 0) {
					item = {
						...item,
						b2b: addD.b2b
					}
				}
		
				const ret = await putData("rayteams-license", item);
			}
		} else {
			
			for (let i = 0; i < list.length; i++) {
				if (list[i].trim()) {
					let item = {
						_id : _id,
						sk : "pl:" + addD.licensetype + ":" + v4(),
						lckey : list[i].trim(),
						deadline : !!addD.regideadline ? addD.regideadline : 99999999999999,
						expired : 99999999999999,
						period : addD.typePeriod == 0 ? (addD?.period || 0) : addD.typePeriod,
						used : "",
						created : new Date().getTime(),
					}
					if (isB2b && addD?.b2b?.length > 0) {
						item = {
							...item,
							b2b: addD.b2b
						}
					}
					const ret = await putData("rayteams-license", item);
				}
			}
		}

		const paramsUpdate = {
			TableName: "rayteams-license",
			Key: {
			  	_id: _id,
			  	sk: `info${(isB2b && addD?.b2b?.length > 0) ? ":" + addD.b2b : ""}`,
			},
			UpdateExpression:
			  	"SET #total = if_not_exists(#total, :initial) + :num",
			ExpressionAttributeValues: {
				":num": managedApp.isray ? parseInt(addD.licensecount) : list.length,
			  	":initial": 0,
			},
			ExpressionAttributeNames: {
			  	"#total": `t_${addD.licensetype}`,
			},
			ReturnValues : "ALL_NEW"
		};

		const upData = await updateData_withExpression(paramsUpdate);

		const paramsDueDate = {
			TableName: "rayteams-license",
			Key: {
				_id: _id, 
				sk: `type:${addD.licensetype}${(isB2b && addD?.b2b?.length > 0) ? ":" + addD.b2b : ""}`
			},
			UpdateExpression:
				"SET #licenseType = :licenseType, #dueDate = :dueDate, #autoSub = :autoSub, #reSub = :reSub, #period = :period",
			ExpressionAttributeNames: {
				"#licenseType": "licensetype",
				"#dueDate": "dueDate",
				"#autoSub": "autosub",
				"#reSub": "noresub",
				"#period": "period"
			},
			ExpressionAttributeValues: {
				":licenseType": addD.licensetype,
				":dueDate": isDueDate && addD?.dueDate ? addD.dueDate : 0,
				":autoSub": isAutoSub,
				":reSub": isNoReSub,
				":period": addD.typePeriod == 0 ? (addD?.period || 0) : addD.typePeriod
			},
		};

		console.log("paramsDueDate : =====", paramsDueDate);
		const typeUpdate = await updateData_withExpression(paramsDueDate);

		RTAudit({ lvl: 7, msg: 'Add a License' }, JSON.stringify({ subject: addD }));
		
		getData();
		getData_pool();
		setLoading(false);
		callbackClose && callbackClose();
		
	}

	return <div>
		<Modal show={modalData.show} size={"lg"} style={{ backgroundColor: "#42476c !important"}}>
			<Modal.Header className='modal-header header-bg'>
				<div className='col-xl-12'>
					<div className='d-flex justify-content-between'>
						<h4>{t("Add License")}</h4>
						<div className='d-flex align-items-center'>
							{t("No ReSubscription")}
							<MLToggle
								options={{
									name: 'isNoReSub',
									value: isNoReSub,
									readonly: false,
								}} 
								handleChanged={(e) => { console.log(e); setIsNoReSub(e.target.value);}}
								css={"ms-2"} />	
						</div>
						<div className='d-flex align-items-center'>
							{t("Auto Subscription")}
							<MLToggle
								options={{
									name: 'isAutoSub',
									value: isAutoSub,
									readonly: false,
								}} 
								handleChanged={(e) => { console.log(e); setIsAutoSub(e.target.value);}}
								css={"ms-2"} />	
						</div>
						<div className='d-flex align-items-center'>
							{t("Due Date")}
							<MLToggle
								options={{
									name: 'isDeuDate',
									value: isDueDate,
									readonly: false,
								}} 
								handleChanged={(e) => { console.log(e); setIsDueDate(e.target.value);}}
								css={"ms-2"} />	
						</div>
						<div className='d-flex align-items-center'>
							{t("B2B")}
							<MLToggle
								options={{
									name: 'isB2b',
									value: isB2b,
									readonly: false,
								}} 
								handleChanged={(e) => { console.log(e); setIsB2b(e.target.value); setAddD({...addD, b2b: ""})}}
								css={"ms-2"} />	
						</div>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body className='flex-fill pt-0 formcard'>
				{!loading && <div>
					<div className='fs-5 my-2'>
						{t("License Type")}
					</div>
						<MLComboSingle options={{
							name: "License Type",
							value: addD.licensetype || "normal",
							list : RMLCTYPES
						}} handleChanged={(e) => {setAddD({...addD, licensetype : e.target.value, typePeriod : parseInt(licenseType.find(f => f.licensetype === e.target.value)?.period) || 0 }); console.log(licenseType.find(f => f.licensetype === e.target.value))}} />
					{ managedApp && isB2b && <>
						<div className='fs-5 my-2 pt-2'>
							{t("B2B Name")}
						</div>
						<MLText options={{
							name: "b2b",
							value: addD?.b2b || ""
						}} handleChanged={(e) => setAddD({...addD, b2b: e.target.value})} />
					</>}
					{ managedApp && managedApp?.isray && <>
						<div className='fs-5 my-2 pt-2'>
							{t("License Count")}
						</div>
						<MLNumber options={{
							name : "license Count",
							value : addD.licensecount || 0,
						}} handleChanged={(e) => setAddD({...addD, licensecount : e.target.value })}  />
					</>}
					{ managedApp && !managedApp?.isray && <>
						<div className='fs-5 my-2 pt-2'>
							{t("Select Registration DeadLine")}
						</div>
							<MLDate options={{
								value : addD.regideadline,
							}} handleChanged={(e) => {setAddD({...addD, regideadline : new Date(e.target.value).valueOf()});} } />
						<div className='fs-5 mt-4 mb-2'>{t("Add License No.(한줄에 1개의 License 작성)")}</div>
						<div>
							<MLTextArea options={{
								name : "license",
								value : addD.lcs,
								rows : 10,
							}} handleChanged={(e) => setAddD({...addD, lcs : e.target.value})}/>
						</div>
					</>}
					{ managedApp && isDueDate && <>
						<div className='fs-5 my-2 pt-2'>
							{t("Select License Due Date")}
						</div>
						<MLDate options={{
							value: addD?.dueDate,
						}} handleChanged={(e) => {console.log("selected DueDate : ===", new Date(e.target.value).valueOf()); setAddD({...addD, dueDate : new Date(e.target.value).valueOf()});}} />
					</>}
					{ managedApp && !isDueDate && addD?.typePeriod == 0 && <>
						<div className='fs-5 my-2 pt-2'>
							{t("Period")}
						</div>
						<MLNumber options={{
							name : "license Period",
							value : addD?.period || 0,
						}} handleChanged={(e) => setAddD({...addD, period : e.target.value })}  />
					</>}
					
				</div>}
				{loading && <MLPanelLoading />}
				<div className='mt-4 text-end'>
					<MLButton
						options={{ label : t("Cancel"), size : "md", color : COLORS.DEFAULT, action : () => callbackClose()}}
						css={'me-1'} />
					<RTButton options={{
						label : loading ? t('Please wait...') : t('Add License'),
						size : 'md',
						ing : loading,
						needValid : true,
						action : () => save()
					}} valid={!loading && !!addD.lcs || (managedApp?.isray && addD.licensecount > 0) || (isB2b && addD?.b2b != "") } css="me-1" />
				</div>
			</Modal.Body>
		</Modal>
	</div>
}

const mapState = (state) => {
    const managedApp = state.AppCategoryReducer.managedApp;
	return { managedApp };
};

export default connect(mapState, null)(RMModalAddPoolLicense);