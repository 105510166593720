import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MLForm } from '../components/common/MLForm';
import { BUTTONS, COLORS, INPUTS } from '../constants/FromInputs';
import { history } from '../history';
import { RTAudit } from '../libs/audit';
import { AWSAdminLoginPasswordUpdate, AWSRMUserPwFlagUpdate } from '../libs/aws';
import { ProgramConstant } from '../redux/reducers';
import { AuthConstant } from '../redux/reducers/AuthReducer';

// const getEnv = () => {
// 	if (location.href.includes("localhost") || location.href.includes("127.0.0.1")) {
// 		return "dev-local";
// 	} else if (location.href.includes("raydevelop")) {
// 		return "development";
// 	}
// 	return "production";
// };

// const getConfigPath = () => {
// 	const now = new Date().getTime();
// 	if (getEnv() === "production") {
// 		return "https://ray-data.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + "ray" + "/config.json" + `?l=${now}`;
// 	} else {
// 		return "https://ray-data-development.s3.ap-northeast-2.amazonaws.com/rayrnd/rayteams-service/" + "ray" + "/config.json" + `?l=${now}`;
// 	}
// }

const AdminPwChangePage = ({ config }) => {
	const dispatch = useDispatch();
	const { _id } = useParams();
	const [data, setData] = useState({ name: "", email: "", password: "" });
	const [loading, setLoading] = useState(false);
	const [t] = useTranslation(['translation']);
	const [passConfirmErr, setPassConfirmErr] = useState('');
	const [days, setDays] = useState(0);
	// const [config, setConfig] = useState({});
	const [isFirstLogin, setIsFirstLogin] = useState(false);

	useEffect(() => {
		if (window?.localStorage?.getItem("_isFirstLogin") === "Y") {
			setIsFirstLogin(true);
		}
	}, []);

	// const fetchConfig = async () => {
	// 	try {
	// 		const configPath = getConfigPath();
	// 		const response = await axios.get(configPath);
	// 		if (response.status === 200) {
	// 			const confData = response.data;
	// 			setConfig(confData);
	// 			dispatch({ type: ProgramConstant.SET_SERVICE, item: confData });
	// 		} else {
	// 			throw new Error(`Unexpected response status: ${response.status}`);
	// 		}
	// 	} catch (error) {
	// 		alert("Failed to load configuration. Please try again later.");
	// 	}
	// };

	// useEffect(() => {
	// 	fetchConfig();
	// }, []);

	useEffect(() => {
		if (config && config.adminPwSecurity) {
			setDays(config.adminPwSecurity.passwordChangePeriod || 0);
		} else {
			setDays(0);
		}
	}, [config]);

	useEffect(() => {
		localStorage.setItem("pwChangeFlag", `/AdminPwChange/${_id}`);
	}, []);


	const updatePassword = async () => {
		setLoading(true);
		try {
			const updatedRet = await AWSAdminLoginPasswordUpdate(_id, data.password, _id);
			if (updatedRet.fail) {
				if (updatedRet.code === "PAST_PASSWORD") {
					alert(t("이전에 사용했던 비밀번호는 다시 사용할 수 없습니다."))
				} else {
					alert(t("오류가 발생했습니다."))
				}
				return;
			}

			const ret = await AWSRMUserPwFlagUpdate(_id);
			if (ret.$response.httpResponse.statusCode === 200) {
				!isFirstLogin && RTAudit({ lvl: 7, msg: `Password updated` });
				localStorage.removeItem("pwChangeFlag");
				dispatch({ type: AuthConstant.LOGOUT });
			}
			setLoading(false);
			alert(t("비밀번호 변경이 완료되었습니다."));
			history.push('/login');
		} catch (error) {
			alert(t("오류가 발생했습니다."));
		}
	};


	const logout = () => {
		localStorage.clear();
		dispatch({ type: AuthConstant.LOGOUT });
		history.push('/login');
	}

	const controls = [
		{
			type: INPUTS.PASSWORD,
			options: {
				label: t('비밀번호'),
				name: 'password',
				required: true,
				useToggle: true,
				isValidPassword: true
			}
		},
		{
			type: INPUTS.PASSWORD,
			options: {
				name: 'repassword',
				label: t('비밀번호 확인'),
				err: passConfirmErr,
				required: true,
				useToggle: true,
				isMatch: true,
				originPassword: data?.password,
			}
		},
		{
			type: BUTTONS.BUTTONS,
			options: {},
			buttons: [{
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('변경'),
					color: COLORS.PRIMARY,
					needValid: true,
					action: () => updatePassword(),
				}
			}, {
				type: BUTTONS.NORMALBUTTON,
				options: {
					label: t('취소'),
					color: COLORS.SECONDARY,
					action: () => logout(),
				}
			}]
		}
	];

	return (
		<div style={{ "width": "540px", "marginLeft": "-4rem" }}>
			{!isFirstLogin ? <>
				<div className='mb-4'>
					<h3 className='pt-2 text-primary mb-2'>{t("개인정보보호를 위해 비밀번호를 변경해 주세요.")}</h3>
					<p className='mb-0'>{t("{{days}}일 이상 동일한 비밀번호를 사용하고 계시네요.", { days })}</p>
					<p>{t("소중한 개인정보보호를 위해 비밀번호를 변경해 주세요.")}</p>
				</div>
			</> : <>
				<div className='mb-4'>
					<h3 className='pt-2 text-primary mb-2'>{t("안전한 사용을 위해 새 비밀번호를 설정해 주세요.")}</h3>
					<p>{t("비밀번호를 변경한 후, 새 비밀번호로 로그인할 수 있습니다.")}</p>
				</div>
			</>}

			<MLForm controls={controls} data={data} onChanged={setData} />
		</div>
	)
}

const mapState = (state) => {
	const adminUsers = state.AuthReducer.adminUsers;
	const user = state.AuthReducer.user;
	const regions = state.AuthReducer.regions;
	const config = state.ProgramReducer.config;
	return { adminUsers, user, regions, config };
};

export default connect(mapState)(AdminPwChangePage);
