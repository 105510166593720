import { combineReducers } from "redux";
import { AuthReducer, AuthConstant } from "./AuthReducer";
import { LicenseReducer, LicenseConstant } from "./LicenseReducer";
import { AppCategoryReducer, AppCategoryConstant } from "./AppCategoryReducer";
import { BoardReducer, BoardConstant } from "./BoardReducer";
import { ProgramReducer, ProgramConstant } from "./ProgramReducer";
import { UsageReducer, UsageConstant } from "./UsageReducer";
import { ProductReducer, ProductConstant } from "./ProductReducer";
import { WFReducer, WFConstant } from "./WFReducer";
import { AlertReducer, AlertConstant } from "./AlertReducer";
import { FeedBackReducer, FeedBackConstant } from "./FeedbackReducer";
import { ProjectReducer, ProjectConstant } from "./ProjectReducer";
import { RTProductReducer, RTProductConstant } from "./RTProductReducer";
import { ManagerGroupReducer, ManagerGroupConstant } from "./ManagerGroupReducer";
import { AppReducer, AppConstant } from "./AppReducer";
import { PaymentReducer, PaymentConstant } from "./PaymentReducer";
import { ApplicationReducer, ApplicationConstant } from "./ApplicationReducer";
import { NotificationReducer, NotifiCationConstant } from "./NotificationReducer";
import { RTFormReducer, RTFormConstant } from "./RTFormReducer";
import { CreditReducer, CreditConstant } from "./CreditReducer";

const appReducer = combineReducers({
    AuthReducer,
    AlertReducer,
    LicenseReducer,
    BoardReducer,
    ProgramReducer,
    UsageReducer,
    AppCategoryReducer,
    ProductReducer,
    WFReducer,
    FeedBackReducer,
    ProjectReducer,
    RTProductReducer,
    ManagerGroupReducer,
    AppReducer,
    ApplicationReducer,
    PaymentReducer,
    NotificationReducer,
    RTFormReducer,
    CreditReducer
});

const RootReducer = (state, action) => {
    if (action.type === AuthConstant.LOGOUT) {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
};

export default RootReducer;
export {
    AuthConstant,
    AlertConstant,
    LicenseConstant,
    BoardConstant,
    ProgramConstant,
    UsageConstant,
    AppCategoryConstant,
    ProductConstant,
    WFConstant,
    FeedBackConstant,
    ProjectConstant,
    RTProductConstant,
    ManagerGroupConstant,
    AppConstant,
    ApplicationConstant,
    PaymentConstant,
    NotifiCationConstant,
    RTFormConstant,
    CreditConstant
};
