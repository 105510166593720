import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { idData } from '../../libs/db';
import { FeedBackConstant } from '../../redux/reducers';
import { MLTextArea } from '../common/MLInput';
import { MLPanelLoading } from '../common/MLPanel';

const RMFeedBackContent = ({ feedback, feedbacks }) => {
	const dispatch = useDispatch();
	const { _id } = useParams();
	const [loading, setLoading] = useState(true);
	const [info, setInfo] = useState({});
    const [data, setData] = useState({});

	useEffect(() => {
		if(!feedbacks && _id) {
            getData_dFeedBack();
        } else {
            genData();
        }
	}, [_id]);

    useEffect(() => {
        feedback?._id && setInfo(feedback);
    }, [feedback])

	const getData_dFeedBack = async () => {
		setLoading(true);

		try {
			const ret = await idData("rayteams-feedback", _id);

			dispatch({ type: FeedBackConstant.GET_FEEDBACK, item : ret.Items });
		} catch (e) {
			dispatch({ type: FeedBackConstant.GET_FEEDBACK, item : {} });
			console.log(e);
		}
		setLoading(false);
	}

    const genData = async () => {
        setInfo(feedbacks.filter(x => x._id === _id)[0])
        setLoading(false)
    }

  	return (
    	<div>
			{loading &&  <>
				<h2 className='mb-4'> FeedBack Info </h2>
				<MLPanelLoading />
			</>}
			{!loading && (info?._id === _id) && <>
				<div className='card mt-5'>
					<div className='card-body'>
						<div className="card-label fw-bold text-gray-800">Contents</div>
                        <div className='section mt-1 mb-3'>
							<MLTextArea options={{
								value : info?.contents,
								name : 'contents',
								required : false,
								readonly : true,
								rows : '4',
								maxheight : '400',
							}} handleChanged={(e) => {console.log(e.target.value); setInfo({...info, contents : e.target.value})}} />
						</div>
						{/*}
						<div className="card-label fw-bold text-gray-800">Links</div>
                        <div className='section mt-1 mb-1'>
                            <div>
								<MLText options={{
									name : 'link1',
									required : false,
								}} handleChanged={(e) => {console.log(e.target.value); setInfo({...info, link1 : e.target.value})}} />
							</div>
                        </div>
                        <div className='section mt-1 mb-1'>
                            <div>
								<MLText options={{
									name : 'link2',
									required : false,
								}} handleChanged={(e) => {console.log(e.target.value); setInfo({...info, link2 : e.target.value})}} />
							</div>
                        </div>
                        <div className='section mt-1 mb-1'>
                            <div>
								<MLText options={{
									name : 'link3',
									required : false,
								}} handleChanged={(e) => {console.log(e.target.value); setInfo({...info, link3 : e.target.value})}} />
							</div>
                        </div>
						*/}                    
					</div>
				</div>
			</>}
    	</div>
  	);
}

const mapState = (state) => {
	const feedback = state.FeedBackReducer.feedback;
    const feedbacks = state.FeedBackReducer.feedbacks;
	return { feedback, feedbacks };
};

const mapDispatch = (dispatch) => ({
	
});

export default connect(mapState)(RMFeedBackContent);